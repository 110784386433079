export const data = [
  {
    id: 1,
    name: "Mozzarella Sticks",
    category: "Appetizers",
    image:
      "https://png.pngtree.com/png-vector/20221124/ourmid/pngtree-under-construction-with-triangle-shape-for-warning-sign-png-image_6471440.png",
    price: "$6.99",
    note: "",
  },
  {
    id: 2,
    name: "Caprese Salad",
    category: "Appetizers",
    image:
      "https://png.pngtree.com/png-vector/20221124/ourmid/pngtree-under-construction-with-triangle-shape-for-warning-sign-png-image_6471440.png",
    price: "$7.99",
    note: "",
  },
  {
    id: 3,
    name: "Bruschetta",
    category: "Appetizers",
    image:
      "https://png.pngtree.com/png-vector/20221124/ourmid/pngtree-under-construction-with-triangle-shape-for-warning-sign-png-image_6471440.png",
    price: "$7.99",
    note: "",
  },
  {
    id: 4,
    name: "Caesar Salad",
    category: "Salads",
    image:
      "https://png.pngtree.com/png-vector/20221124/ourmid/pngtree-under-construction-with-triangle-shape-for-warning-sign-png-image_6471440.png",
    price: "$9.99",
    note: "",
  },
  {
    id: 5,
    name: "Wedge Salad",
    category: "Salads",
    image:
      "https://png.pngtree.com/png-vector/20221124/ourmid/pngtree-under-construction-with-triangle-shape-for-warning-sign-png-image_6471440.png",
    price: "$10.99",
    note: "",
  },
  {
    id: 6,
    name: "House Salad",
    category: "Salads",
    image:
      "https://png.pngtree.com/png-vector/20221124/ourmid/pngtree-under-construction-with-triangle-shape-for-warning-sign-png-image_6471440.png",
    price: "$10.99",
    note: "",
  },
  {
    id: 7,
    name: "Italian Salad",
    category: "Salads",
    image:
      "https://png.pngtree.com/png-vector/20221124/ourmid/pngtree-under-construction-with-triangle-shape-for-warning-sign-png-image_6471440.png",
    price: "$11.99",
    note: "",
  },
  {
    id: 8,
    name: "Spaghetti and Meatballs",
    category: "Pasta",
    image:
      "https://png.pngtree.com/png-vector/20221124/ourmid/pngtree-under-construction-with-triangle-shape-for-warning-sign-png-image_6471440.png",
    price: "$22.99",
    note: "",
  },
  {
    id: 9,
    name: "Lasagna",
    category: "Pasta",
    image:
      "https://png.pngtree.com/png-vector/20221124/ourmid/pngtree-under-construction-with-triangle-shape-for-warning-sign-png-image_6471440.png",
    price: "$23.99",
    note: "",
  },
  {
    id: 10,
    name: "Gnocchi",
    category: "Pasta",
    image:
      "https://png.pngtree.com/png-vector/20221124/ourmid/pngtree-under-construction-with-triangle-shape-for-warning-sign-png-image_6471440.png",
    price: "$26.99",
    note: "",
  },
  {
    id: 11,
    name: "Penne alla Vodka",
    category: "Pasta",
    image:
      "https://png.pngtree.com/png-vector/20221124/ourmid/pngtree-under-construction-with-triangle-shape-for-warning-sign-png-image_6471440.png",
    price: "$23.99",
    note: "",
  },
  {
    id: 12,
    name: "Ravioli",
    category: "Pasta",
    image:
      "https://png.pngtree.com/png-vector/20221124/ourmid/pngtree-under-construction-with-triangle-shape-for-warning-sign-png-image_6471440.png",
    price: "$24.99",
    note: "",
  },
  {
    id: 13,
    name: "Tortellini",
    category: "Pasta",
    image:
      "https://png.pngtree.com/png-vector/20221124/ourmid/pngtree-under-construction-with-triangle-shape-for-warning-sign-png-image_6471440.png",
    price: "$24.99",
    note: "",
  },
  {
    id: 14,
    name: "Classic Italian Pizza",
    category: "Pizza",
    image:
      "https://png.pngtree.com/png-vector/20221124/ourmid/pngtree-under-construction-with-triangle-shape-for-warning-sign-png-image_6471440.png",
    price: "$15.99",
    note: "",
  },
  {
    id: 15,
    name: "Margherita Pizza",
    category: "Pizza",
    image:
      "https://png.pngtree.com/png-vector/20221124/ourmid/pngtree-under-construction-with-triangle-shape-for-warning-sign-png-image_6471440.png",
    price: "$17.99",
    note: "",
  },
  {
    id: 16,
    name: "New York Style Pizza",
    category: "Pizza",
    image:
      "https://png.pngtree.com/png-vector/20221124/ourmid/pngtree-under-construction-with-triangle-shape-for-warning-sign-png-image_6471440.png",
    price: "$15.99",
    note: "",
  },
  {
    id: 17,
    name: "Pizza Napoletana",
    category: "Pizza",
    image:
      "https://png.pngtree.com/png-vector/20221124/ourmid/pngtree-under-construction-with-triangle-shape-for-warning-sign-png-image_6471440.png",
    price: "$19.99",
    note: "",
  },
  {
    id: 18,
    name: "Sicilian Pizza",
    category: "Pizza",
    image:
      "https://png.pngtree.com/png-vector/20221124/ourmid/pngtree-under-construction-with-triangle-shape-for-warning-sign-png-image_6471440.png",
    price: "$18.99",
    note: "",
  },
  {
    id: 19,
    name: "Chicken Parmesan",
    category: "Chicken",
    image:
      "https://png.pngtree.com/png-vector/20221124/ourmid/pngtree-under-construction-with-triangle-shape-for-warning-sign-png-image_6471440.png",
    price: "$19.99",
    note: "",
  },
  {
    id: 20,
    name: "Grilled Tuscan Chicken",
    category: "Chicken",
    image:
      "https://png.pngtree.com/png-vector/20221124/ourmid/pngtree-under-construction-with-triangle-shape-for-warning-sign-png-image_6471440.png",
    price: "$18.99",
    note: "",
  },
  {
    id: 21,
    name: "Tiramisu",
    category: "Desserts",
    image:
      "https://png.pngtree.com/png-vector/20221124/ourmid/pngtree-under-construction-with-triangle-shape-for-warning-sign-png-image_6471440.png",
    price: "$6.99",
    note: "",
  },
  {
    id: 22,
    name: "Chocolate Cake",
    category: "Desserts",
    image:
      "https://png.pngtree.com/png-vector/20221124/ourmid/pngtree-under-construction-with-triangle-shape-for-warning-sign-png-image_6471440.png",
    price: "$6.99",
    note: "",
  },
  {
    id: 23,
    name: "Brownie with Vanilla Ice Cream",
    category: "Desserts",
    image:
      "https://png.pngtree.com/png-vector/20221124/ourmid/pngtree-under-construction-with-triangle-shape-for-warning-sign-png-image_6471440.png",
    price: "$7.99",
    note: "",
  },
  {
    id: 24,
    name: "Panna Cotta",
    category: "Desserts",
    image:
      "https://png.pngtree.com/png-vector/20221124/ourmid/pngtree-under-construction-with-triangle-shape-for-warning-sign-png-image_6471440.png",
    price: "$7.99",
    note: "",
  },
  {
    id: 25,
    name: "Gelato",
    category: "Desserts",
    image:
      "https://png.pngtree.com/png-vector/20221124/ourmid/pngtree-under-construction-with-triangle-shape-for-warning-sign-png-image_6471440.png",
    price: "$5.99",
    note: "",
  },
  {
    id: 26,
    name: "Cannoli",
    category: "Desserts",
    image:
      "https://png.pngtree.com/png-vector/20221124/ourmid/pngtree-under-construction-with-triangle-shape-for-warning-sign-png-image_6471440.png",
    price: "$5.99",
    note: "",
  },
];

const quotes = [
  {
    quote:
      "The ambiance transports you straight to Rome, and the pasta is as authentic as it gets! Truly a gem.",
  },
  {
    quote:
      "Every dish at Kiss of Rome tastes like it's made with love. The flavors are rich and remind me of my time in Italy.",
  },
  {
    quote:
      "Outstanding service! The staff at Kiss of Rome make you feel like family. The Risotto is a must-try!",
  },
  {
    quote:
      "We celebrated our anniversary at Kiss of Rome and it was magical. The tiramisu is the best I've ever had!",
  },
  {
    quote:
      "From the fresh ingredients to the exquisite wine list, everything at Kiss of Rome screams quality.",
  },
  {
    quote:
      "Kiss of Rome is our go-to for date night. The romantic setting and the divine Spaghetti Carbonara never disappoint.",
  },
  {
    quote:
      "If you want a true taste of Italian cuisine, Kiss of Rome is the place to go. Their seafood dishes are out of this world!",
  },
  {
    quote:
      "Never thought I’d find such authentic Italian cuisine outside of Italy. The Lasagna is sublime and a real tribute to traditional recipes.",
  },
];

export function getRandomQuote() {
  const randomIndex = Math.floor(Math.random() * quotes.length);
  return quotes[randomIndex].quote;
}
